import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import swal from "sweetalert";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/user.jpg";
import deposit_icon from "../../../../images/svg/deposit.svg";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

  const AppProfile = () => {
  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  
  if (auth_token !== null) {
    const params = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9', // Add your key here
      currency: selectedCurrency, // Add the currency parameter
    };

    axios.get('/home-data', { params })
      .then(response => {
          console.log("home.");
         })
      .catch(error => {
        console.log(error);
      });
  }


const [showText, setShowText] = useState(false);
const [SetYouImg, AYouImage] = useState(null)
const [ggs_photo, ApiYouImage] = useState([]);
const [loading, setLoading] = useState(false);

  const [registerInput, setRegister] = useState({
    your_image:'',
      error_list: [],
  });

const YourImage = (event) => {
 if (event.target.files && event.target.files[0]) {
   AYouImage(URL.createObjectURL(event.target.files[0]));
   ApiYouImage({your_image: event.target.files[0] });
   setShowText(true);
 }
}

  const submitProduct=(e) =>{
  	if(!loading){
 	  setLoading(true);
      e.preventDefault();
      const formData= new FormData();
      formData.append('key_s', "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9");
      formData.append('your_image', ggs_photo.your_image);
      axios.post('/change-profile-photo', formData)
      .then((res)=>{
  	  setLoading(false);
            if(res.data.status === 'success')
            {
                swal("Success",res.data.message,"success");
            }
           else if(res.data.status === 'fail')
            {
                swal("Warning",res.data.message,"warning");
            }
            else
            {
                setRegister({...registerInput, error_list: res.data.validation_error});
            }
     		setLoading(false);
        setShowText(false);
      });
	}
  }

  const [user, setUser] = useState([]);
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let picture_Url;
	let Email;
	let Username;
	let mobile_No;
	let name;
	let balance;
	let bonus_balance;
	let total_deposit;
	let total_Income;
	let total_withdraw;
	let identity_status;
	let affiliate_earning;
	if (user == "") {
		console.log(user);
	}else{

		if(user.data.picture_Url === '0'){
			picture_Url = profile;
		}else{
			picture_Url = BaseUrl+user.data.picture_Url;
		}
		Email=user.data.email;
		name=user.data.name;
		mobile_No=user.data.mobile_No;
		Username=user.data.username;
		balance=user.data.balance;
		bonus_balance=user.data.bonus_balance;
		total_deposit=user.data.total_deposit;
		total_Income=user.data.total_Income;
		total_withdraw=user.data.total_withdraw;
		identity_status=user.data.identity_status;
		affiliate_earning=user.data.affiliate_earning;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">





						<form onSubmit={submitProduct} enctype= "multipart/form-data">
                <div className="profile-photo">
				        <i className="fa fa-edit" style={{ cursor: "pointer" }}>
				            <input 
				                type="file" 
				                id="formFile" 
				                name="your_image"
				                onChange={YourImage}
				                style={{
				                    position: "absolute",
				                    opacity: 0,
				                    width: "100%",
				                    height: "100%",
				                    left: 0,
				                    top: 0,
				                    cursor: "pointer"
				                }}
				            />
				        </i>

                  <img
                    src={ SetYouImg || picture_Url || profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
            {showText && (
						<button type="submit" className="btn btn-primary sw-btn-next ms-1 mt-1">{loading ? t('comon_Submitting') : t('comon_Submit')}</button>
            )}	                
            </form>
	
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{Username}</h4>
                    <span>{t('pro_pg_username')}</span>

                  <div>
							
                   { identity_status === 1 ? 
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#7ed321" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                       :
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                    } 
									<span style={{ 
										    color: identity_status === 1 
										      ? 'green' 
										      : identity_status === 2 
										      ? 'gold' 
										      : 'red' 
										  }}>
										  {identity_status === 1 
										    ? ' Active' 
										    : identity_status === 2 
										    ? ' Submitted' 
										    : ' Inactive'}
										</span>
                  </div>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



						<div className="col-xl-12">
							<div className="row">
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<img  width="50" height="50"
			                    src={deposit_icon}
			                    alt="profile"
			                  />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(balance).toFixed(4))}</h4>
													<p className="mb-0 text-black">{t('pro_pg_curr_bal')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
{/*								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<img  width="50" height="50"
			                    src={deposit_icon}
			                    alt="profile"
			                  />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(bonus_balance).toFixed(4))}</h4>
													<p className="mb-0 text-black">{t('pro_pg_bon_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>*/}
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<img  width="50" height="50"
			                    src={deposit_icon}
			                    alt="profile"
			                  />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_deposit).toFixed(4))}</h4>
													<p className="mb-0 text-black ">{t('pro_pg_tot_dep')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<img  width="50" height="50"
			                    src={deposit_icon}
			                    alt="profile"
			                  />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_Income).toFixed(4))}</h4>
													<p className="mb-0 text-black">{t('pro_pg_tot_inc')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<img  width="50" height="50"
			                    src={deposit_icon}
			                    alt="profile"
			                  />
												<div className="ms-4">
													<h4 className="mb-0 font-w600">{CurrencySymbol +" "+ parseFloat(parseFloat(total_withdraw).toFixed(4))}</h4>
													<p className="mb-0 text-black">{t('pro_pg_tot_wit')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										{t('pro_pg_info')}
									</h4>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_name')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500"> {t('pro_pg_username')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">{Username}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_mob_num')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">{mobile_No}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_email')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">{Email}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">{t('pro_pg_age')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">..</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-3">
											<h5 className="f-w-500">  {t('pro_pg_location')}<span className="pull-right">:</span></h5>
										</div>
										<div className="col-9">
											<span className="text-black">..</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
