import React,{useState ,useReducer, useContext, useRef, useEffect} from "react";
import { Link ,useHistory, useLocation} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Image
import profile_h from "../../../images/user.jpg";
import avatar from "../../../images/logo.png";
import LogoutPage from './Logout';
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import { GlobalSet } from '../../components/global/GlobalProvider';
import { Row, Card, Col, Button, Modal, Container, Dropdown ,Badge } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Header = ({ onNote }) => {
  const { CurrencySymbol } = GlobalSet();
  const { BaseUrl } = GlobalSet();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const auth_token = localStorage.getItem('auth_token');

  var withdraw = BaseUrl + "public/appssource/sports_icons/withdraw.png";
  var deposit = BaseUrl + "public/appssource/sports_icons/deposit.png";
  var security = BaseUrl + "public/appssource/sports_icons/security.png";
  var support_ticket = BaseUrl + "public/appssource/sports_icons/support_ticket.png";
  var log_out = BaseUrl + "public/appssource/sports_icons/log_out.png";
  var transaction_history = BaseUrl + "public/appssource/sports_icons/transaction_history.png";
  var bet_history = BaseUrl + "public/appssource/sports_icons/bet_history.png";
  var profile = BaseUrl + "public/appssource/sports_icons/profile.png";
  var income_history = BaseUrl + "public/appssource/sports_icons/Income_history.png";
  var affiliate = BaseUrl + "public/appssource/sports_icons/affiliate.png";

  // Check if ?login is in the URL, and open the modal
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("login")) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [location]);

  // Close the modal and remove ?login from the URL
  const handleClose = () => {
    setShow(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("login");
    history.replace({
      search: searchParams.toString(),
    });
  };

  // Open the modal by adding ?login to the URL
  const handleShowLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("login", "true");
    history.replace({
      search: searchParams.toString(),
    });
  };

  const logoutSubmit = (e) => {
        e.preventDefault();
        axios.post(`/logout`).then(res => {
        	console.log(res.data.message)
            if(res.data.message === 'Unauthenticated')
            {
                localStorage.removeItem('auth_token');
                //swal("Success",res.data.message,"success");
                //history.push('/');
	             swal("Success", res.data.message, "success");
	             window.location.href = '/'; 
            }
        });
    }
      axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401)
        {
        	localStorage.removeItem('auth_token');
            swal("Unauthorized",err.response.data.message,"warning");
            history.push('/');
        }
        return Promise.reject(err);
    });

    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            username: loginInput.username,
            password: loginInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
        }
            axios.post(`/apps-user-login`, data).then(res => {
                if(res.data.status === 'success')
                {
                    localStorage.setItem('auth_token', res.data.accessToken);
                    //swal("Success",res.data.message,"success");
                    //history.push('/');
                    swal("Success",res.data.message,"success");
	                window.location.href = '/'; 
 
                }
                else if(res.data.status === 'fail')
                {

                    swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setLogin({...loginInput, error_list: res.data.validation_error});
                }
            });
          }


  const [showModalCountry, setShowModalCoun] = useState(false);
  const modalRefCountry = useRef(null);

  const toggleModalCountry = () => {
    setShowModalCoun(!showModalCountry);
  };

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRefCountry.current && !modalRefCountry.current.contains(event.target)) {
        setShowModalCoun(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRefCountry]);


  const [showModalSetting, setShowModalSetting] = useState(false);
  const modalRefSetting = useRef(null);

  const toggleModalSetting = () => {
    setShowModalSetting(!showModalSetting);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRefSetting.current && !modalRefSetting.current.contains(event.target)) {
        setShowModalSetting(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRefSetting]);


  const handleLanClick = (divLang) => {
    localStorage.setItem('selectedLanguage', divLang);
    window.location.reload();
  };

  let  divLang;
  const sel_Language = localStorage.getItem('selectedLanguage');
  if (sel_Language !== null) {
   divLang = sel_Language;
  } else {
   divLang = "en";
  }
  const [selectedLanguage, setSelectedLanguage] = useState(divLang);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };


  const handleCarClick = (divCarr) => {
    localStorage.setItem('selectedCurrency', divCarr);
      let  cur_icon;
	  if (divCarr === "USD") {
	   cur_icon = "$";
	  } else if (divCarr === "EUR"){
	   cur_icon = "€";
	  } else if (divCarr === "BDT"){
	   cur_icon = "৳";
	  } else if (divCarr === "INR"){
	   cur_icon = "₹";
	  }
    localStorage.setItem('CurrencySymbol', cur_icon);
    window.location.reload();
  };
  let  divCarr;
  const sel_cudrrency = localStorage.getItem('selectedCurrency');
  if (sel_cudrrency !== null) {
   divCarr = sel_cudrrency;
  } else {
   divCarr = "USD";
  }
  const [selectedCurrency, setSelectedCurrency] = useState(divCarr);
  
  const [settingToggle, setSettingToggle] = useState(false);
  const [demoToggle, setDemoToggle] = useState(false);

   const [user, setUser] = useState(null);
   const [balance, setBalance] = useState(0); // Separate balance state
	 const fetchProfileData = () => {
	    const data = {
	      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
	      currency: selectedCurrency,
	    };
	    if (auth_token !== null) {
	      axios.post('/apps-profile', data)
	        .then(response => {
	          setUser(response.data);
	          setBalance(response.data?.data?.balance || 0); // Update balance
	        })
	        .catch(error => {
	          console.log(error);
	        });
	    }
	  };
	  useEffect(() => {
	    fetchProfileData();
	    const intervalId = setInterval(fetchProfileData, 5000);
	    return () => clearInterval(intervalId);
	  }, []);


var AuthButtons = '';
if(!localStorage.getItem('auth_token'))
{
  AuthButtons = (
	<ul className="navbar-nav header-right">

	    <div as="li" className="nav-item">
	        <Button variant="outline-primary btn-square btn-xxs" onClick={handleShowLogin}>
	            <strong>Login</strong>
	        </Button>
	    </div>
	    <Modal show={show} onHide={handleClose}>
	        <Modal.Header closeButton>
	            <Modal.Title>Login Here</Modal.Title>
	        </Modal.Header>
	         <Modal.Body>
	            <form onSubmit={loginSubmit}>
		            <div className="form-group mb-3">
		               <div className="form-group">
		                  <div className="form-group mb-3">
		                       <label className="mb-1">
		                          <strong className="text-black">Username</strong>
		                        </label>
		                    <input
		                      type="text"
		                      className="form-control"
		                      placeholder="username"
		                       name="username"
		                     onChange={handleInput} 
		                     value={loginInput.username}
		                      />
		                   </div>
		                  <span>{loginInput.error_list.username}</span>
		                 

                        <label className="text-label text-black">Password</label>
                        <div className="input-group transparent-append mb-2">
                          
                            <span className="input-group-text">
                              {" "}
                              <i className="fa fa-lock" />{" "}
                            </span>
                          
                          <input
                            type={`${showPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-password1"
                            placeholder="password"
                            name="password"
                            onChange={handleInput} 
                            value={loginInput.password}
                          />

                          <div
                            className="input-group-text "
                            onClick={() => setShowPassword(!showPassword)}
                          >

                              {" "}
								  {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                            
                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                          </div>


                        </div>
		                 <span>{loginInput.error_list.password}</span>



		                  <div className="text-center mt-4">
		                      <button type="submit" className="btn btn-primary btn-block">
		                        Login
		                      </button>
		                  </div>
		              </div>
		            </div>
	        	</form>
	        	<div className="new-account mt-3">
					<p><span className="text-black">New account? <Link className="text-black" to="./register">Sign up</Link></span>
					<span style={{ float: 'right' }}><Link className="text-black" to="./forgot-password">Forgot Password ?</Link></span>
					</p>
				</div>
	        </Modal.Body>
	    </Modal>
	    <Dropdown as="li" className="nav-item">
		    <Link to="/register" >
				<Button variant="outline-primary btn-square btn-xxs" >
				    <strong>signup</strong>
				</Button>
			</Link>
		</Dropdown>
	</ul>
   );
    }
    else
    {
  AuthButtons = (
	<ul className="navbar-nav header-right">
		<Dropdown as="li" className="nav-item dropdown header-profile" style={{ marginRight: '0px'}}>

        <ul className="navbar-nav header-right d-none d-sm-block">
            <div as="li" className="nav-item">     
             <Link to="/deposit">
                <button type="button" class="me-2 btn btn-warning">Deposit</button>
               </Link>
            </div>
        </ul>

        <button type="button" className="me-2 btn btn-dark btn-rounded with-nav-p">
        	  <span className="d-none d-sm-block btn-icon-start text-primary" onClick={fetchProfileData} >
            <i className="ti-reload"></i>
       		  </span> 

          <Link style={{ fontsize: '10px' }} to="/deposit" >
          <span className="d-block d-sm-none">
	        	  <span className="btn-icon-start text-primary" >Deposit</span>       
          </span>
          </Link>  

          {CurrencySymbol}{parseFloat(parseFloat(balance).toFixed(2))} 
        </button>

			<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
				<img src={profile_h} width={30} alt="Profile" />
			</Dropdown.Toggle>
			<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">

        <Link to="/app-profile" className="dropdown-item ai-icon">
          <img src={profile} alt="app profile" style={{ width: '25px', height: '25px'}} />
          <span className="ms-2"> {t('he_pro_profile')} </span>
        </Link>
				<Link to="/deposit" className="dropdown-item ai-icon">
					<img src={deposit} alt="deposit" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_dep_his')} </span>
				</Link>					
				<Link to="/mybet-history" className="dropdown-item ai-icon">
					<img src={bet_history} alt="mybet history" style={{ width: '25px', height: '25px'}} />
				  <span className="ms-2"> {t('he_pro_bet_his')} </span>
				</Link>
				<Link to="/income-history" className="dropdown-item ai-icon">
					<img src={income_history} alt="income history" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_inc_his')} </span>
				</Link>
				 <Link to="/transaction-history" className="dropdown-item ai-icon">
					<img src={transaction_history} alt="transaction history" style={{ width: '25px', height: '25px'}} />
					<span className="ms-2"> {t('he_pro_tra_his')} </span>
				</Link>
				<Link to="/withdraw" className="dropdown-item ai-icon">
					<img src={withdraw} alt="withdraw" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('he_pro_with_his')} </span>
				</Link>
        <Link to="/affiliate" className="dropdown-item ai-icon">
          <img src={affiliate} alt="app profile" style={{ width: '25px', height: '25px'}} />
          <span className="ms-2">Affiliate </span>
        </Link>
				<Link to="/security" className="dropdown-item ai-icon">
					<img src={security} alt="security" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('he_pro_sec')} </span>
				</Link>
				<Link to="/support-ticket" className="dropdown-item ai-icon">
					<img src={support_ticket} alt="support-ticket" style={{ width: '25px', height: '25px'}}  />
					<span className="ms-2"> {t('support_ticket')} </span>
				</Link>
				<Link to="" className="dropdown-item ai-icon">
					<img src={log_out} alt="log out"  style={{ width: '25px', height: '25px'}} />
					<span onClick={logoutSubmit} className="ms-2"> {t('he_pro_logout')} </span>
				</Link>
			</Dropdown.Menu>
		</Dropdown>
	</ul>
        );
    }

const [searchBut, setSearchBut] = useState(false);	
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  return ( 
    <div style={{ zIndex: 998 }} className="header border-bottom">
        <div className="header-content des-nav-ziro">
		    <nav className="navbar navbar-expand">
		      <div className="collapse navbar-collapse justify-content-between">
		        <div className="header-left">
					<div
						className="dashboard_bar"
						style={{ textTransform: "capitalize" }}
					  >
						{finalName.join(" ").length === 0
						  ? "OddBet"
						  : finalName.join(" ") === "dashboard dark"
						  ? "OddBet"
						  : finalName.join(" ")}
					</div>
		       </div>

			<div className="nav-item d-flex align-items-center">
				<div className="input-group search-area">
					<input
					  type="text"
					  className={`form-control ${searchBut ? "active" : ""}`}
					  placeholder="Search.."
					/>
					<span className="input-group-text" onClick={() => setSearchBut(!searchBut)}>
						<Link to={"#"}><i className="flaticon-381-search-2 text-black"></i></Link>
					</span>
				</div>
			</div> 


		     <ul className="navbar-nav header-right">
						<ul className="navbar-nav header-right">
						    <div as="li" className="nav-item">     
						     <Link to="/">
					    		  <img style={{ width:"90%" }} className="form-inline d-block d-sm-none" src={avatar} alt=""/>
     							 </Link>
						    </div>
						</ul>

           <Dropdown as="li" className="nav-item dropdown notification_dropdown des-rg-ziro">
            <Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a">
              <svg xmlns="http://www.w3.org/2000/svg" width="19.375" height="24" viewBox="0 0 19.375 24">
                <g id="_006-notification" data-name="006-notification" transform="translate(-341.252 -61.547)">
                  <path id="Path_1954" data-name="Path 1954" d="M349.741,65.233V62.747a1.2,1.2,0,1,1,2.4,0v2.486a8.4,8.4,0,0,1,7.2,8.314v4.517l.971,1.942a3,3,0,0,1-2.683,4.342h-5.488a1.2,1.2,0,1,1-2.4,0h-5.488a3,3,0,0,1-2.683-4.342l.971-1.942V73.547a8.4,8.4,0,0,1,7.2-8.314Zm1.2,2.314a6,6,0,0,0-6,6v4.8a1.208,1.208,0,0,1-.127.536l-1.1,2.195a.6.6,0,0,0,.538.869h13.375a.6.6,0,0,0,.536-.869l-1.1-2.195a1.206,1.206,0,0,1-.126-.536v-4.8a6,6,0,0,0-6-6Z" transform="translate(0 0)" fill="#135846" fill-rule="evenodd"/>
                  </g>
              </svg>
              <span className="badge light text-white bg-primary rounded-circle">1</span>
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="mt-2 dropdown-menu dropdown-menu-end">
              <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                <ul className="timeline">
                  <li>
                  <div className="timeline-panel">
                    <div className="media me-2 d-none d-sm-block">
                       <Link to="/">
                        <img alt="images" width={50} src={avatar} />
                       </Link>
                    </div>
                    <div className="media-body">
                      <h6 className="mb-1">Hello Dear, Welcome to OddBet .</h6>
                    </div>
                  </div>
                  </li>
                </ul>
                <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                  <div
                  className="ps__thumb-x"
                  tabIndex={0}
                  style={{ left: 0, width: 0 }}
                  />
                </div>
                <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                  <div
                  className="ps__thumb-y"
                  tabIndex={0}
                  style={{ top: 0, height: 0 }}
                  />
                </div>
              </PerfectScrollbar>
              <Link className="all-notification" to="#">
                See all notifications <i className="ti-arrow-right" />
                </Link>
            </Dropdown.Menu>
            </Dropdown>
            <Dropdown as="li" className="nav-item dropdown header-profile" style={{ marginRight: '0px'}}>
                <button type="button"  onClick={toggleModalCountry} className="me-2 btn btn-dark btn-rounded d-none d-sm-block">
                  <span className="fa fa-globe text-white" onClick={fetchProfileData} >
                  {' ' + divLang.toUpperCase() + ' , '}
                  </span>
                  {' ' + divCarr }
                </button>
             </Dropdown>

        {showModalCountry && (
          <div className="modal show" tabIndex="-1" role="dialog" style={{ display: "block" }}>
            <div className="modal-dialog" role="document" ref={modalRefCountry} style={{ marginTop: '100px' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Select Country</h5>
                  <button type="button" onClick={toggleModalCountry} aria-label="Close">
                    <span style={{ padding: '5px', fontSize: '10px' }} aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col col-lg-6">
                      <div className="mail-list rounded mt-4">
                        <Link onClick={() => handleLanClick('en')} className={`list-group-item text-black ${divLang === 'en' && "active"}`}>
                          <i className="fa fa-language font-18 align-middle me-2 text-black"></i> English
                        </Link>
                        <Link onClick={() => handleLanClick('fr')} className={`list-group-item text-black ${divLang === 'fr' && "active"}`}>
                          <i className="fa fa-language font-18 align-middle me-2 text-black"></i> Français
                        </Link>
                        <Link onClick={() => handleLanClick('bn')} className={`list-group-item text-black ${divLang === 'bn' && "active"}`}>
                          <i className="fa fa-language font-18 align-middle me-2 text-black"></i> বাংলা
                        </Link>
                        <Link onClick={() => handleLanClick('hi')} className={`list-group-item text-black ${divLang === 'hi' && "active"}`}>
                          <i className="fa fa-language font-18 align-middle me-2 text-black"></i> हिंदी
                        </Link>
                      </div>
                    </div>

                    <div className="col col-lg-6 text-black">
                      <div className="mail-list rounded text-black mt-4">
                        <Link onClick={() => handleCarClick('USD')} className={`list-group-item text-black ${divCarr === 'USD' && "active"}`}>
                          <i className="fa fa-globe font-18 align-middle me-2 text-black"></i> USD
                        </Link>
                        <Link onClick={() => handleCarClick('EUR')} className={`list-group-item text-black ${divCarr === 'EUR' && "active"}`}>
                          <i className="fa fa-globe font-18 align-middle me-2 text-black"></i> EUR
                        </Link>
                        <Link onClick={() => handleCarClick('BDT')} className={`list-group-item text-black ${divCarr === 'BDT' && "active"}`}>
                          <i className="fa fa-globe font-18 align-middle me-2 text-black"></i> BDT
                        </Link>
                        <Link onClick={() => handleCarClick('INR')} className={`list-group-item text-black ${divCarr === 'INR' && "active"}`}>
                          <i className="fa fa-globe font-18 align-middle me-2 text-black"></i> INR
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={toggleModalCountry}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
					 {AuthButtons}
			    </ul>
		      </div>
		    </nav>
        </div>


      <div className="sidebar-right">
        <button
          type="button"
          onClick={toggleModalCountry}
          style={{ display: "block" }}
          className="sidebar-right-trigger wave-effect wave-effect-x"
        >
          <span>
            <i className="fa fa-cog fa-spin" />
          </span>
        </button>


      </div>


    </div>
  );
};

export default Header;
